<template>
   <div class="main">
        <div class="kefan">
            <div class="col" style="margin-bottom: 20px;">
                <div class="title">水电费表格上传</div>
                <div>
                  <el-date-picker
                    v-model="value"
                    type="month"
                    @change ="changeMonth"
                    value-format="yyyy-MM"
                    placeholder="选择月">
                  </el-date-picker>
                </div>
            </div>
            <div class="drag">
                <el-upload
                    class="upload-demo"
                    drag
                    :action="url"
                    :limit="1"
                    :file-list="fileList"
                    :on-success="handleSuccess"
                    :on-preview="handlePreview"
                    :on-exceed="handleExceed"
                    :before-remove="beforeRemove"
                    :on-remove="handleRemove"
                    >
                    
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
            </div>  
            <div class="drag" style="margin-top: 30px;">
                <el-button type="primary" v-if="waitList.length" @click="uploadfile" >上传系统</el-button>
                <el-button type="info" v-else disabled >上传系统</el-button>
            </div>
        </div>

        <!-- 下载 -->
        <div class="kefan" v-if="month_show">
            <div class="col">
                <div class="title">{{value}}水电费抄表情况</div>
            </div>
            <div class="drag download" style="margin-top: 40px;">
                <div class="box primary">
                    <div class="title">总表数</div>
                    <div class="num">{{ st.total }}</div>
                </div>
                <div class="box success">
                    <div class="title">已抄电表数</div>
                    <div class="num">{{ st.read }}</div>
                </div>
                <div class="box warning">
                    <div class="title">未抄电表数</div>
                    <div class="num">{{ st.unread }}</div>
                </div>
            </div>

            <!-- 进度条 -->
            <div class="drag" style="margin-top: 40px;">
              <el-progress type="dashboard" :percentage="percent" :color="colors"></el-progress>
            </div>  

            <div class="drag" style="margin-top: 40px;">
                <el-button type="primary" @click="downloadfile" >下载表格</el-button>
            </div>
        </div>
   </div>
</template>
<script>
import config from '../../config';
import axios from "axios";
let api = config.API;
export default {
   data() {
     return {
       url:api.uploadexcel,
       fileList: [],
       waitList:[],
       loading:false,
       value: '',
       st:[],
       month_show:true,
       colors: [
          {color: '#f56c6c', percentage: 20},
          {color: '#e6a23c', percentage: 40},
          {color: '#5cb87a', percentage: 60},
          {color: '#1989fa', percentage: 80},
          {color: '#6f7ad3', percentage: 100}
        ],
        percent:0
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     this.init()
   },
   methods: {
    // 初始化参数
    init(){
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if(month < 10){
        month = '0' + month
      }
      this.value = year + '-' + month
      this.loadMonthData()
    },



    // 上传成功后的钩子函数
    handleSuccess(response, file, fileList) {
      // 上传成功后的逻辑，比如更新文件列表等
      this.fileList = fileList;
      let obj = {
        filename:response.data.filename,
        url:response.data.url
      }
      this.waitList.push(obj)
      console.log(this.waitList);
    },
    // 预览文件
    handlePreview(file) {
      // 获取文件URL
      const url = URL.createObjectURL(file.raw);
      // 打开新窗口显示文件内容
      window.open(url);
    },
    // 删除前
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    // 删除文件 
    handleRemove(file) {
        // 获取文件索引
        const index = this.fileList.indexOf(file);
        // 从文件列表中移除文件
        this.waitList.splice(index, 1);
        console.log(this.waitList);
    },
    // 超过限制
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制上传 1 个文件，本次选择了 ${ files.length } 个文件，共选择了 ${ files.length + fileList.length } 个文件`);
    },

    // 上传文件至系统进行导入
    uploadfile(){
      let loading = this.loading
      if(loading){
        return
      }
      this.loading = true
      let showloading = this.$loading({
        lock: true,
        text: '导入数据库中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$confirm('确定上传文件至系统？').then(() => {
        this.$post(api.excel,{
          waitList:this.waitList,
          month:this.value,
          do:"upload"
        }).then(res=>{
          this.loading = false
          showloading.close()
          this.$message({
            type: 'success',
            message: res.data.msg
          });
          this.waitList = []
          this.fileList = []
          this.loadMonthData()
        }).catch(()=>{
          this.loading = false
          showloading.close()
          this.$message({
            type: 'error',
            message: '上传失败'
          });
        })
      }).catch(() => {
        this.loading = false
        showloading.close()
        this.$message({
          type: 'info',
          message: '已取消上传'
        });          
      });
    },

    changeMonth(){
      this.loadMonthData()
    },

    // 加载该月数据
    loadMonthData(){
      this.$post(api.excel,{
        month:this.value,
        do:"load"
      }).then(res=>{
        if(res.data.code==200){
          this.st = res.data.data
          this.percent = Number((this.st.read / this.st.total * 100).toFixed(2))
          this.month_show = true
        }else{
          this.st = []
          this.month_show = false
        }
        
      }).catch(()=>{
        this.$message({
          type: 'error',
          message: '加载失败'
        });
      }).catch(()=>{
        this.$message({
          type: 'error',
          message: '加载失败'
        });
      })
    },

    // 导出当前查询的数据
    downloadfile() {
      let loading = this.loading
      if(loading){
        return
      }
      this.loading = true
      let showloading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
          axios({
            method: "post",
            url: api.excel,
            headers: {
              'Content-Type':'application/json',
            },
            responseType:'blob',
            data: {
              do:"download",
              month:this.value
            },
          }).then((res)=>{
            this.loading = false
            showloading.close()
            let url=window.URL.createObjectURL(new Blob([res.data]));
            let link=document.createElement('a')
            link.download=this.value+'抄表数据.xlsx'
            link.href=url
            link.click()
          }).catch(()=>{
            this.loading = false
            showloading.close()
            this.$message({
              type: 'error',
              message: '导出失败'
            });
          });
      },
      
   }

};
</script>
<style scoped>
.main{
    width:100%;
}
.drag{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}
.download{
  gap: 5%;
}
.col{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.box{
  width: 25%;
  height: 75px;
  padding: 20px;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.success{
  background-color: #E9F7F0;
  color: #2D8764;
}
.primary{
  background-color: #f2f4ff;
  color: #252424;
}
.warning{
  background-color:#F0F0F0 ;
  color: #5E605F;
}
</style>